/* eslint-disable jsx-a11y/alt-text */

export const MarketingCard = (props) => {
  const numOfCol =
    props.numInRow === 1
      ? "tablet:grid-cols-1"
      : props.numInRow === 2
      ? "tablet:grid-cols-2"
      : props.numInRow === 3
      ? "tablet:grid-cols-3"
      : props.numInRow === 4
      ? "tablet:grid-cols-4"
      : null;
  return (
    <div>
      <div className="">
        <div className={`phone:grid ${numOfCol} flex-wrap`}>
          {props.mailingList.map((listDetails, index) => (
            <div className="mb-4" key={index}>
              <QueryInterest
                listDetails={listDetails}
                chinese={props.chinese}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const QueryInterest = (props) => {
  const { name, url_string, url, body } = props.listDetails;

  const handleEmailClick = () => {
    const emailAddress = "Marketing@ensigninfosecurity.com";
    const mailtoLink = `mailto:${emailAddress}`;
    window.location.href = mailtoLink;
  };
  return (
    <div className="pt-2 flex flex-col desktop:h-48 tablet:h-52 border-2 mr-4">
      <div className="mb-4">
        <h1 className="card_header_font_size mx-4">{name}</h1>

        <div>
          <h1 className="card_body_font_size mx-4">{body}</h1>
        </div>
      </div>
      {url !== null ? (
        <div className="flex items-end mt-auto mb-4 ">
          <a className="bottom-0 z-10 left-0" href={url}>
            <button className="hover:underline">
              <p className="card_body_font_size text-secondary-B200 mx-4">
                {url_string}
              </p>
            </button>
          </a>
        </div>
      ) : (
        <div>
          <button className="hover:underline" onClick={handleEmailClick}>
            {" "}
            <p className="card_body_font_size text-secondary-B200 mx-4">
              {url_string}
            </p>
          </button>
        </div>
      )}
    </div>
  );
};
