import React, { useEffect } from "react";
import { BottomBanner } from "../banners/BottomBanner";
import { Footer } from "../misc/Footer";
import { MarketingCard } from "../card/MarketingCard";

import {
  CONTACTUS,
  CONTACTUSDATA,
  CONTACTUSCALLOURSALESTEAM,
  CONTACTUSSALESTEAM,
  CONTACTUKEEPINTOUCH,
  CONTACTUSCARD,
  CAPABILITIES_ACCORDION_TITLE,
  CAPABILITIES_ACCORDION,
  BOTTOM_BANNER_DETAILS,
  FOOTER_DETAILS,
} from "../../constant/text";

const ContactUs = () => {
  useEffect(() => {
    document.title = CONTACTUS;
  }, []);

  return (
    <>
      <div className="py-4 tablet:max-w-screen-xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <div className="flex flex-wrap no-gutters">
          <div className="tablet:w-3/4 pr-4 pl-4 bg-white">
            <div className="p-6">
              <div className="header_font_size py-4">{CONTACTUSDATA.name}</div>
              <div className="body_font_size">{CONTACTUSDATA.body}</div>

              <div className="header_font_size py-4">{CONTACTUKEEPINTOUCH}</div>
              <MarketingCard numInRow={2} mailingList={CONTACTUSCARD} />
            </div>
          </div>
          <div className="tablet:w-1/4 tablet:mx-auto mx-4 pr-4 pl-4 ">
            <div className="py-8">
              <div className="text-lg mb-8">{CONTACTUSCALLOURSALESTEAM}</div>
              {CONTACTUSSALESTEAM.map((contact, index) => (
                <>
                  <div key={index} className="text-lg font-bold">
                    {contact.name}
                  </div>
                  <div
                    className="text-lg py-4"
                    dangerouslySetInnerHTML={{
                      __html: contact.article_body,
                    }}
                  />
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <BottomBanner bannerDataImage={BOTTOM_BANNER_DETAILS} />
      </div>
      <div className="py-4 tablet:max-w-screen-xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <Footer footerLinksData={FOOTER_DETAILS} />
      </div>
    </>
  );
};

export default ContactUs;
