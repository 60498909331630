import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavigationBar from "./components/misc/NavigationBar.tsx";
import "./App.css";
import "./global.scss";
import { ChakraProvider } from "@chakra-ui/react";
import { SSRProvider } from "@react-aria/ssr";
import Cookiebanner from "./components/banners/Cookiebanner";
import Main from "./components/pages/Main";
import Capabilities from "./components/pages/Capabilities";
import Patents from "./components/pages/Innovations/Patents.js";
import Innovations from "./components/pages/Innovations/Innovations.js";
import Cloud from "./components/pages/Service Listings/Cloud.js";
import Detection from "./components/pages/Service Listings/Detection.js";
import ExecutiveAdvisory from "./components/pages/Service Listings/ExecutiveAdvisory.js";
import CombatRansomware from "./components/pages/use-cases/CombatRansomware.js";
import Leadership from "./components/pages/about-ensign/Leadership.js";
import Resources from "./components/pages/Resources.js";
import Careers from "./components/pages/about-ensign/careers.js";
import Tgp from "./components/pages/about-ensign/Tgp.js";
import ContactUs from "./components/pages/ContactUs";

import { NAV_BAR, WHISTLEBLOWINGPOLICY } from "./constant/text.js";
import SecureCloudJourney from "./components/pages/use-cases/SecureCloudJourney.js";
import ResourcesDetails from "./components/pages/ResourcesDetails.js";
import PrivacyPolicy from "./components/pages/Privacy-Policy.js";
import TermsOfUse from "./components/pages/Terms-Of-Use.js";
import Whistleblowing from "./components/pages/Whistleblowing.js";

function App() {
  return (
    <SSRProvider>
      <NavigationBar navBarData={NAV_BAR} navBarCarouselData={[]} />
      <ChakraProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/capabilities" element={<Capabilities />} />
            <Route path="/patents" element={<Patents />} />
            <Route path="/innovations" element={<Innovations />} />
            <Route
              path="/services/services-listings/cloud"
              element={<Cloud />}
            />
            <Route
              path="/services/services-listings/executive-advisory"
              element={<ExecutiveAdvisory />}
            />
            <Route
              path="/services/services-listings/detection"
              element={<Detection />}
            />
            <Route path="/resources/:id" element={<ResourcesDetails />} />
            <Route
              path="/solutions/use-cases/combat-ransomware"
              element={<CombatRansomware />}
            />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            <Route path="/whistleblowing-policy" element={<Whistleblowing />} />

            <Route
              path="/solutions/use-cases/secure-cloud-journey"
              element={<SecureCloudJourney />}
            />
            <Route path="/resources" element={<Resources />} />
            <Route
              path="/about-ensign/our-leadership"
              element={<Leadership />}
            />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-ensign/careers" element={<Careers />} />
            <Route path="/about-ensign/careers/tgp" element={<Tgp />} />
          </Routes>
        </Router>
      </ChakraProvider>
      <Cookiebanner />
    </SSRProvider>
  );
}

export default App;
